<template>
  <div class="layout-header-section">
    <div class="header-label col-lg-6 col-12">Virtual Numbers</div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 268px)">
    <DataTable :value="virtualNumberList" :scrollable="true" scrollHeight="flex" :paginator="false" :lazy="true"
      :rowHover="true" :totalRecords="totalRecords"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="mobileno" header="Mobile Number" headerStyle="width: 22%" bodyStyle="width: 22%">
        <template #body="{ data }">
          <div class="w-100">
            <div class="d-flex align-items-center justify-content-between" v-if="!primaryinputboxshow">
              <div class="label-subheading">
                {{
                  data.mobile_one != "" && data.mobile_one != null
                  ? "+91 " + data.mobile_one
                  : "No Number"
                }}
              </div>
              <div>
                <button type="button" title="Edit" class="btn custom-outline-view-btn ms-4"
                  @click="editForwardedNumberOne(data)">
                  <i class="pi pi-pencil call-btn-color"></i>
                </button>
              </div>
            </div>
            <div class="d-flex align-items-center" v-if="primaryinputboxshow">
              <div class="input-group custom-search-input-outer">
                <span class="input-group-text label-subheading ps-2" id="basic-addon1">+91</span>
                <input type="text" v-model="forwardingnumberone"
                  class="form-control custom-form-search-group text-capitalize" placeholder="Enter Mobile No"
                  maxlength="10" @keypress="onlyNumber" autocomplete="off" />
              </div>
              <div>
                <button type="button" title="Save" class="btn custom-outline-save-btn ms-2"
                  @click="saveForwardedNumberOne()" :disabled="forwardingnumberone.length != 10">
                  <i class="pi pi-check save-btn-color"></i>
                </button>
              </div>
            </div>
          </div>
        </template>
      </Column>
      <Column field="virtualnumber" header="Virtual Number" headerStyle="width: 34%" bodyStyle="width: 34%">
        <template #body="{ data }">
          <div class="label-subheading mb-2">{{ "0" + data.cn2 }} (CB)</div>
        </template>
      </Column>
      <Column field="status" header="Status" headerStyle="width: 10%" bodyStyle="width: 10%">
        <template #body="{ data }">
          <div class="label-subheading">
            <span class="appointment-badge status-scheduled" v-if="data.cn16 == 0">Free</span>
            <span class="appointment-badge status-scheduled" v-if="data.cn16 == 1">Active</span>
            <span class="appointment-badge status-cancelled" v-if="data.cn16 == 2">Notice Period</span>
            <span class="appointment-badge status-cancelled" v-if="data.cn16 == 3">Request For Delete</span>
            <span class="appointment-badge status-cancelled" v-if="data.cn16 == 3">Release</span>
          </div>
        </template>
      </Column>
      <Column field="action" header="Action" class="justify-content-center" headerStyle="width: 12%"
        bodyStyle="width: 12%">
        <template #body="{ data }">
          <button type="button" class="btn custom-view-detail-btn" @click="redirectToVirtualSetting(data)">
            Settings
          </button>
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="col-lg-12 col-md-12 col-12 mt-3">
    <div class="customer-label-group mb-1 d-flex align-items-center">
      <div class="from-label-value text-danger">Important Notes :</div>
    </div>
    <div class="customer-label-group d-flex align-items-center">
      <ol class="list-group list-group-flush">
        <li class="form-label list-group-item">1. Please do not display or publish CALLBACK NUMBER anywhere, these numbers
          can be changed without any prior notice due to TRAI guidelines.</li>
        <li class="form-label list-group-item">2. You have to forward the mobile number to the provided virtual number.
        </li>
      </ol>
    </div>
  </div>
</template>
<script>
import ApiService from "../../../service/ApiService";

export default {
  data() {
    return {
      virtualNumberList: null,
      totalRecords: 0,
      loading: false,
      forwardingnumberone: "",
      forwardingnumbertwo: "",
      primaryinputboxshow: false,
      secondaryinputboxshow: false,
      forwarded_number_detail_one: "",
      forwarded_number_detail_two: "",
    };
  },
  ApiService: null,

  created() {
    this.ApiService = new ApiService();
  },

  mounted() {
    this.loading = true;
    this.getvirtualnumberslist();
  },

  methods: {
    getvirtualnumberslist(e) {
      this.loading = true;
      this.ApiService.getvirtualnumberslist(e).then((data) => {
        if (data.status == 200) {
          this.virtualNumberList = data.record;
          this.totalRecords = data.count;
          this.loading = false;
        } else {
          this.loading = false;
          this.virtualNumberList = null;
          this.totalRecords = 0;
        }
      });
    },

    addupdatesetting(e) {
      var fields = {};
      fields["number_id"] = e.cn1;
      fields["settingid"] = e.ca1;
      this.ApiService.addupdatesetting(fields).then((data) => {
        if (data.status == 200) {
          this.addressSettingList = data.record;
        }
      });
    },

    redirectToVirtualSetting(e) {
      // console.log("re", e);
      this.addupdatesetting(e);
      this.ca1 = e.ca1 == null ? 0 : e.ca1;
      let routePath = "/cloudtelephony/virtualnumbers/set";
      this.$router.push(`${routePath}/${btoa(e.cn1)}/${btoa(this.ca1)}`);
    },

    editForwardedNumberOne(e) {
      this.primaryinputboxshow = true;
      this.forwarded_number_detail_one = e;
    },

    saveForwardedNumberOne() {
      var fields = {};
      fields["mobile_number"] = this.forwardingnumberone;
      fields["type"] = 1;
      fields["numberDetails"] = this.forwarded_number_detail_one;
      this.ApiService.savemobilenumber(fields).then((data) => {
        if (data.success == true) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.primaryinputboxshow = false;
          this.forwarded_number_detail_one = "";
          this.forwardingnumberone = "";
          this.getvirtualnumberslist();
        } else {
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },

    editForwardedNumberTwo(e) {
      this.secondaryinputboxshow = true;
      this.forwarded_number_detail_two = e;
    },

    saveForwardedNumberTwo() {
      var fields = {};
      fields["mobile_number"] = this.forwardingnumbertwo;
      fields["type"] = 2;
      fields["numberDetails"] = this.forwarded_number_detail_two;
      this.ApiService.savemobilenumber(fields).then((data) => {
        if (data.success == true) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.secondaryinputboxshow = false;
          this.forwarded_number_detail_two = "";
          this.forwardingnumbertwo = "";
          this.getvirtualnumberslist();
        } else {
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }
    },
  },
};
</script>
<style scoped>
.custom-outline-save-btn {
  background: #e4f8f0 0% 0% no-repeat padding-box;
  border: 1px solid #bbd9cc;
  border-radius: 4px;
  padding: 7.5px 9px;
  line-height: 10px;
}

.custom-outline-save-btn .save-btn-color {
  color: #2ca270;
  font-size: 13px;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}
</style>